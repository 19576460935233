import { VisibilityStatus, WinCategories } from 'app/packs/src/types/entities'
import { store } from 'store/index'
import { User } from 'store/modules/users'
import { Win } from 'store/modules/wins'
import { z } from 'zod'
import { zfd } from 'zod-form-data'

export class WinFormVm {
  constructor(
    private currentUser: User,
    private userIds: string[],
    private category: WinCategories,
    private source: string,
    private initialContent?: string,
    private intitialSkillIds?: string[],
    private win?: Win
  ) {}

  async onSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()
    const formData = new FormData(e.currentTarget)

    const baseUserSchema = z.preprocess(
      (val) => (typeof val === 'string' ? [val] : val),
      z.array(zfd.text())
    )

    const userSchema = this.win ? baseUserSchema.optional() : baseUserSchema

    const schema = zfd.formData({
      users: userSchema,
      content: zfd.text(),
      skills: z
        .preprocess(
          (val) => (typeof val === 'string' ? [val] : val),
          z.array(zfd.text())
        )
        .optional(),
      visibility_status: z
        .literal('reporting_line')
        .or(z.literal('org'))
        .or(z.literal('only_me')),
    })

    const {
      users,
      content,
      skills = [],
      visibility_status,
    } = schema.parse(formData)

    const baseProps = {
      content,
      skills: skills,
      visibility: visibility_status,
    }

    if (this.win) {
      await store.wins.update(this.win.id, baseProps, { source: this.source })
    } else {
      if (!users) return

      await store.wins.create(
        {
          ...baseProps,
          reporter: this.currentUser.id,
          winners: users,
          winCategory: this.category,
        },
        { source: this.source }
      )
    }
  }

  get defaultContentValue() {
    return this.win?.content || this.initialContent
  }

  get defaultSkillIds() {
    if (this.win) {
      return this.win.skillIds
    } else {
      return this.intitialSkillIds
    }
  }

  get placeholderText() {
    return this.category === 'Win'
      ? "What have they done, and what's the impact?"
      : 'Make a note of the little things in-between the Wins and Actions that contribute to your career story'
  }

  get defaultVisibilityStatus() {
    if (this.win) {
      return this.win.visibility
    } else {
      if (this.category === 'Note') return 'only_me'
      return this.isForCurrentUser ? 'reporting_line' : 'org'
    }
  }

  get visibilityStatuses() {
    const visibilityStatuses = ['reporting_line'] as VisibilityStatus[]
    if (this.isForCurrentUser) visibilityStatuses.push('only_me')
    if (this.category === 'Win') visibilityStatuses.push('org')
    return visibilityStatuses
  }

  get isForCurrentUser() {
    const { currentUser } = store
    return this.userIds.length === 1 && this.userIds[0] === currentUser?.id
  }

  get buttonText() {
    return this.win ? 'Update' : 'Add'
  }
}

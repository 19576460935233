import { currentUrl } from '../utils/url-helpers'

export const utmSource = (): string => {
  return currentUrl().searchParams.get('utm_source') || ''
}

export const utmMedium = (): string => {
  return currentUrl().searchParams.get('utm_medium') || ''
}

export const originParam = (): string => {
  return currentUrl().searchParams.get('origin') || ''
}

export const sourceFromParams = (): string => {
  const medium = utmMedium()
  const source = utmSource()

  if (!medium && !source) {
    return originParam()
  }

  return `${medium}_${source}`
}

export const urlParamTracking = () => {
  return {
    source: sourceFromParams(),
    utmSource: utmSource(),
    utmMedium: utmMedium(),
    originParam: originParam(),
  }
}

import * as React from 'react'
import cn from 'classnames'
import { CheckboxIcon } from 'components/checkbox-icon'
import { Star } from '@phosphor-icons/react'
import { components, OptionProps } from 'react-select'
import { TSkillOption } from '../skill-select.types'
import { observer } from 'mobx-react-lite'

export const Option = observer((props: OptionProps<TSkillOption, true>) => {
  const {
    isSelected,
    isFocused,
    label,
    data: { isFocusSkill },
  } = props

  return (
    <components.Option
      {...props}
      className={cn(
        isFocused && 'bg-gray-50',
        isSelected && !isFocused && 'bg-transparent',
        'hover:!bg-gray-50 hover:cursor-pointer px-4 py-3 flex flex-row items-center gap-x-2 text-gray-900'
      )}
    >
      <OptionContent
        isSelected={isSelected}
        isFocusSkill={isFocusSkill}
        label={label}
      />
    </components.Option>
  )
})

export type OptionContentProps = {
  isSelected?: boolean
  label: string
  isFocusSkill: boolean
}

export const OptionContent = observer((props: OptionContentProps) => {
  const { isSelected, label, isFocusSkill } = props

  return (
    <>
      <CheckboxIcon checked={isSelected} />
      {isFocusSkill && (
        <>
          <Star
            aria-hidden
            weight="fill"
            className="w-4 h-4 text-theme-50 flex-shrink-0"
          />
          <span className="sr-only">(focused)</span>
        </>
      )}
      {label}
    </>
  )
})

import {
  Checks,
  CircleWavyCheck,
  Megaphone,
  Note,
  Clipboard,
  Notebook,
} from '@phosphor-icons/react'
import { Link, TabbedContent } from 'src/design-system'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import cn from 'classnames'
import { ActionForm } from 'components/forms/action-form'
import { CheckinForm } from 'components/forms/checkin-form'
import { FeedbackForm } from 'components/forms/feedback-form'
import { store } from 'store/index'
import { successToast } from '../../utils/success-toast'
import { User } from 'store/modules/users'
import { WinForm } from 'components/forms/win-form'
import { StoryForm } from 'components/forms/story-form'

export type Tab = 'note' | 'win' | 'action' | 'feedback' | 'checkin' | 'story'
export type Tabs = Tab[]

export type CreateFormProps = {
  initialTabId?: Tab
  tabs?: Tabs
  source: string
  user: User
  onSuccess?: () => void
  autoFocus?: boolean
  initialUserIds?: string[]
  initialSkillIds?: string[]
  createdFromId?: string
  createdFromType?: string
  resourceId?: string
  resourceType?: 'Action' | 'Win' | 'Note' | 'FeedbackRequest'
  initialContent?: string
  // whether the feedback form should force start on the from tab
  startOnFromTab?: boolean
}

type TabConfig = {
  title: string
  icon: React.ReactNode
  successMessage?: React.ReactNode
}

export const CreateForm = observer((props: CreateFormProps) => {
  const { activities, userSkills, featureFlags } = store
  const storiesEnabled = featureFlags.featureEnabled('stories')
  const defaultTabs: Tabs = ['win', 'action', 'feedback', 'checkin', 'note']
  if (storiesEnabled) defaultTabs.push('story')

  const {
    tabs = defaultTabs,
    initialTabId = tabs[0],
    source,
    user,
    onSuccess,
    autoFocus,
    initialUserIds,
    initialSkillIds,
    createdFromId,
    createdFromType,
    resourceId,
    resourceType,
    initialContent = '',
    startOnFromTab,
  } = props

  const initialTabIdWithFallback = tabs.includes(initialTabId)
    ? initialTabId
    : tabs[0]

  const [content, setContent] = React.useState(initialContent)

  let actionTitle = 'Action'
  if (!user.isCurrentUser) actionTitle += ` for ${user.fname}`

  const tabConfig: Record<Tab, TabConfig> = {
    action: {
      title: actionTitle,
      icon: <Checks weight="bold" className="text-green-600" />,
      successMessage: (
        <ActionSuccessMessage user={user} action={resourceType === 'Action'} />
      ),
    },
    checkin: {
      title: 'Check-in',
      icon: <Clipboard weight="bold" className="text-pink-600" />,
      successMessage: 'New Check-in started!',
    },
    feedback: {
      title: 'Feedback',
      icon: <Megaphone weight="bold" className="text-yellow-600" />,
    },
    note: {
      title: 'Note',
      icon: <Note weight="bold" className="text-blue-600" />,
      successMessage: (
        <WinSuccessMessage resourceId={resourceId} resourceType={'Note'} />
      ),
    },
    win: {
      title: 'Win',
      icon: <CircleWavyCheck weight="bold" className="text-green-400" />,
      successMessage: (
        <WinSuccessMessage resourceId={resourceId} resourceType={'Win'} />
      ),
    },
    story: {
      title: 'Story',
      icon: <Notebook weight="bold" className="text-orange-600" />,
      successMessage: 'Creating your story, hold tight...',
    },
  }

  const tabProps = (tab: Tab) => ({
    initialContent: content,
    setContent,
    initialSkillIds,
    source,
    async onSuccessfulSubmit() {
      setContent('')
      if (tabConfig[tab].successMessage)
        successToast(tabConfig[tab].successMessage)
      await activities.fetchAllWithAllIncludes(20, undefined, undefined, true)
      onSuccess?.()
    },
    focusSkillIds: userSkills.focusedSkillIdsForUser(user.id),
    autoFocus,
  })

  const tabContent: Record<Tab, React.ReactNode> = {
    action: (
      <ActionForm
        createdFromId={createdFromId}
        createdFromType={createdFromType}
        actionId={resourceType === 'Action' ? resourceId : undefined}
        skills={user.sortedSkills()}
        user={user}
        {...tabProps('action')}
      />
    ),
    checkin: <CheckinForm source={source} />,
    feedback: (
      <FeedbackForm
        startOnFromTab={startOnFromTab}
        initialUserIds={initialUserIds}
        feedbackRequestId={
          resourceType === 'FeedbackRequest' ? resourceId : undefined
        }
        {...tabProps('feedback')}
      />
    ),
    note: (
      <WinForm
        winId={resourceType === 'Note' ? resourceId : undefined}
        category="Note"
        {...tabProps('note')}
      />
    ),
    win: (
      <WinForm
        winId={resourceType === 'Win' ? resourceId : undefined}
        initialUserIds={initialUserIds}
        {...tabProps('win')}
      />
    ),
    story: storiesEnabled ? <StoryForm /> : null,
  }

  const isSingleTab = tabs.length === 1

  return (
    <TabbedContent.Root
      initialTabId={initialTabIdWithFallback}
      id="create-form"
      data-testid="create-form"
    >
      <div className="pt-6 px-6 mb-4 flex">
        <TabbedContent.Tabs
          variant="pill"
          className="gap-1 overflow-y-auto"
          data-element-id="create-form-tabs"
        >
          {tabs.map((tab) => (
            <TabbedContent.Tab
              className={cn(isSingleTab && 'p-0 bg-transparent')}
              data-element-id={`create-form-tab-${tab}`}
              id={tab}
              key={tab}
            >
              {tabConfig[tab].icon}
              {tabConfig[tab].title}
            </TabbedContent.Tab>
          ))}
        </TabbedContent.Tabs>
      </div>
      {tabs.map((tab) => (
        <TabbedContent.Content tabId={tab} key={tab}>
          {tabContent[tab]}
        </TabbedContent.Content>
      ))}
    </TabbedContent.Root>
  )
})

const ActionSuccessMessage: React.VFC<{ action?: boolean; user: User }> = (
  props
) => {
  const { action, user } = props

  if (action) return <div>Action updated!</div>

  if (!user.isCurrentUser)
    return (
      <div>
        Action created!{' '}
        <Link className="text-white" href={user.profileUrl}>
          View Action
        </Link>
      </div>
    )

  return (
    <div>
      <div>Action created! Be sure to check it off once it&apos;s done ✅</div>
      <Link className="text-white" href={user.actionsUrl}>
        View Action
      </Link>
    </div>
  )
}

const WinSuccessMessage: React.VFC<{
  resourceId?: string
  resourceType: 'Win' | 'Note'
}> = (props) => {
  const { resourceId, resourceType } = props

  const content = resourceId
    ? `${resourceType} updated!`
    : `New ${resourceType}  added!`

  return (
    <div>
      {content}
      {!resourceId && resourceType === 'Win' && (
        <Link className="text-white" href={`/wins`}>
          View Win
        </Link>
      )}
    </div>
  )
}

import * as React from 'react'
import cn from 'classnames'
import { VisibilityStatus } from '../types/entities'
import { Buildings, LockSimple, Users } from '@phosphor-icons/react'
import { Tag, TagProps } from 'src/design-system'

export type VisibilityIconProps = {
  visibility: VisibilityStatus
  className?: string
  includeTag?: boolean
  /**
   * Depending on where the paper tag is used, it may need to be darkened (ie for create form dropdowns with hover)
   * @default false
   */
  useDarkPaperTag?: boolean
}

export const VisibilityIcon: React.VFC<VisibilityIconProps> = (props) => {
  const { visibility, className, includeTag = true, useDarkPaperTag } = props

  const Icon = {
    only_me: LockSimple,
    reporting_line: Users,
    org: Buildings,
  }[visibility]

  const tagVariant: TagProps['variant'] = Object.freeze({
    only_me: 'orange',
    reporting_line: useDarkPaperTag ? 'dark' : 'paper',
    org: 'success',
  })[visibility]

  const rawIconClass = Object.freeze({
    only_me: 'text-orange-400',
    reporting_line: 'text-gray-600',
    org: 'text-green-600',
  })[visibility]

  if (!includeTag)
    return (
      <Icon
        aria-hidden
        weight="bold"
        className={cn(rawIconClass, 'h-4 w-4 flex-shrink-0', className)}
      />
    )

  return (
    <Tag variant={tagVariant} className={cn(className, 'p-1')}>
      <Icon aria-hidden weight="bold" className="h-3 w-3 flex-shrink-0" />
    </Tag>
  )
}

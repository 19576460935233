import * as React from 'react'
import { GroupHeadingProps, components } from 'react-select'
import { TSkillOption } from '../skill-select.types'

export const GroupHeading = (props: GroupHeadingProps<TSkillOption, true>) => (
  <components.GroupHeading
    {...props}
    className="text-xs text-gray-600 font-bold mt-1.5 mb-2 px-3"
  />
)

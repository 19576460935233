import * as ToggleGroup from '@radix-ui/react-toggle-group'
import cn from 'classnames'
import React, { createContext, FC, ReactNode, useContext, VFC } from 'react'

const ToggleState = createContext<{ value?: string }>({})

export const ToggleItem: FC<{
  value: string
  className?: string
}> = ({ value, className, children }) => {
  const state = useContext(ToggleState)
  const active = state.value === value

  return (
    <ToggleGroup.Item
      value={value}
      disabled={active}
      className={cn('py-1 rounded transition-all', className)}
    >
      {children}
    </ToggleGroup.Item>
  )
}

type ToggleProps = {
  onChange: (val: string) => void
  value: string
  className?: string
  children(value: string): ReactNode
}

export const Toggle: VFC<ToggleProps> = ({
  value,
  className,
  onChange,
  children,
}) => {
  return (
    <ToggleGroup.Root
      type="single"
      value={value}
      onValueChange={onChange}
      className={cn(className)}
    >
      <ToggleState.Provider value={{ value }}>
        {children(value)}
      </ToggleState.Provider>
    </ToggleGroup.Root>
  )
}

import { Button } from 'src/design-system'
import * as Dropdown from '@radix-ui/react-dropdown-menu'
import { CalendarBlank, CaretDown } from '@phosphor-icons/react'
import * as React from 'react'
import { DatePicker } from '../date-picker'
import { DateSelectVm } from './date-select-vm'

export type DateSelectProps = {
  /**
   * Name for the hidden input field - defaults to 'date'
   */
  name?: string
  /**
   * The default value for the date, this will render in a hidden input and will be overridden internally when the value changes
   */
  defaultValue?: Date
  /**
   * Placeholder text to be shown in the trigger, defaults to "By any time"
   */
  placeholder?: string
}

export const DateSelect: React.VFC<DateSelectProps> = (props) => {
  const { name = 'date', defaultValue, placeholder = 'By any time' } = props

  const [selectedDate, setSelectedDate] = React.useState<Date | undefined>(
    defaultValue
  )
  const [inputValue, setInputValue] = React.useState<string>(
    defaultValue?.toISOString() || ''
  )

  const [open, setOpen] = React.useState(false)

  const vm = React.useMemo(() => {
    return new DateSelectVm(selectedDate, placeholder)
  }, [selectedDate, placeholder])

  const handleDaySelect = React.useCallback((date: Date | undefined) => {
    setSelectedDate(date)
    if (date) {
      setInputValue(date.toISOString())
      setOpen(false)
    } else {
      setInputValue('')
    }
  }, [])

  return (
    <Dropdown.Root open={open} onOpenChange={setOpen}>
      <Dropdown.Trigger asChild>
        <Button
          colourVariant="paper"
          className="flex flex-row gap-x-1 focus:bg-gray-50 shadow-none"
        >
          <CalendarBlank
            weight="bold"
            className="w-4 h-4 text-gray-600"
            aria-hidden
          />
          <span className={vm.hasValue ? 'text-gray-900' : 'text-gray-600'}>
            {vm.triggerContent}
          </span>
          <CaretDown
            weight="bold"
            className="w-4 h-4 text-gray-600"
            aria-hidden
          />
          <input type="hidden" name={name} value={inputValue} />
        </Button>
      </Dropdown.Trigger>
      <Dropdown.Portal>
        <Dropdown.Content
          align="start"
          avoidCollisions={false}
          className="ml-0 mt-px bg-white border border-gray-100 shadow-card rounded"
        >
          <DatePicker
            selected={selectedDate}
            initialFocus={open}
            defaultMonth={selectedDate}
            onSelect={handleDaySelect}
            mode="single"
            required={false}
          />
        </Dropdown.Content>
      </Dropdown.Portal>
    </Dropdown.Root>
  )
}

import { z } from 'zod'
import { zfd } from 'zod-form-data'
import { Action } from 'store/modules/actions'
import { RequestResult } from 'app/packs/src/api/types'
import { Skill } from 'store/modules/skills'
import { store } from 'store/index'
import { User } from 'store/modules/users'

export class ActionFormVm {
  constructor(
    private user: User,
    private source: string,
    private action?: Action,
    private actionSkills?: Skill[],
    private initialSkillIds: string[] = [],
    private content?: string,
    private createdFromId?: string,
    private createdFromType?: string
  ) {}

  async onSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()

    const formData = new FormData(e.currentTarget)

    const schema = zfd.formData({
      content: zfd.text(z.string().min(1)),
      due_date: zfd.text(z.string().optional()),
      skill_ids: z.preprocess(
        (val) => (typeof val === 'string' ? [val] : val),
        z.array(zfd.text())
      ),
    })

    const { content, due_date, skill_ids } = schema.parse(formData)

    const baseProps = {
      content,
      dueDate: due_date ? new Date(due_date) : null,
      skills: skill_ids,
      createdFromId: this.createdFromId,
      createdFromType: this.createdFromType,
    }

    let result: RequestResult

    if (this.action) {
      result = await store.actions.update(
        this.action.id,
        { ...baseProps },
        { source: this.source }
      )
    } else {
      result = await store.actions.create(
        { ...baseProps, user: this.user.id },
        { source: this.source }
      )
    }

    const id =
      result.success && result.data
        ? Object.keys(result.data.actions)[0]
        : undefined

    return id
  }

  get actionText() {
    return this.action ? 'Update' : 'Add'
  }

  get defaultContentValue() {
    return this.action?.content || this.content
  }

  get defaultSkillIds() {
    if (this.actionSkills) {
      return this.actionSkills.map((skill) => skill.id)
    } else if (this.action) {
      return this.action.skills.map((skill) => skill.id)
    } else {
      return this.initialSkillIds
    }
  }

  get placeholder() {
    return this.user.isCurrentUser
      ? 'What are you going to get done?'
      : `What would you like ${this.user.fname} to get done?`
  }
}

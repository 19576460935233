import * as React from 'react'
import { ControlProps, components } from 'react-select'
import { TSkillOption } from '../skill-select.types'

export const Control = (props: ControlProps<TSkillOption, true>) => (
  <components.Control
    {...props}
    className="border-0 border-solid border-b border-gray-100 shadow-none min-h-0 rounded-b-none"
  />
)

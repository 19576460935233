import * as React from 'react'
import { format } from 'date-fns'
import { CaretLeft, CaretRight } from '@phosphor-icons/react'
import {
  CaptionProps,
  DayPicker,
  DayPickerProps,
  useNavigation,
} from 'react-day-picker'
import 'react-day-picker/dist/style.css'
import styles from './date-picker.module.scss'

export type DatePickerProps = DayPickerProps

export const DatePicker = (props: DatePickerProps) => {
  return (
    <DayPicker
      classNames={{
        root: styles.dayPicker,
        month: styles.month,
        head_cell: styles.head_cell,
      }}
      modifiersClassNames={{}}
      components={{ Caption }}
      {...props}
    />
  )
}

const Caption = (props: CaptionProps) => {
  const { goToMonth, nextMonth, previousMonth } = useNavigation()

  const buttonClass =
    'rounded-full p-0.5 flex items-center focus-visible:ring-theme-50 focus-visible:ring-2 outline-none'

  return (
    <h2 className="text-base font-bold flex flex-row items-center pl-2">
      {format(props.displayMonth, 'MMM yyy')}
      <div className="pl-2 ml-auto flex flex-row items-center gap-x-1">
        <button
          disabled={!previousMonth}
          onClick={() => previousMonth && goToMonth(previousMonth)}
          className={buttonClass}
          type="button"
        >
          <CaretLeft
            weight="bold"
            aria-hidden
            className="w-4 h-4 text-gray-600"
          />
          <span className="sr-only">Previous</span>
        </button>
        <button
          disabled={!nextMonth}
          onClick={() => nextMonth && goToMonth(nextMonth)}
          className={buttonClass}
          type="button"
        >
          <CaretRight
            weight="bold"
            aria-hidden
            className="w-4 h-4 text-gray-600"
          />
          <span className="sr-only">Next</span>
        </button>
      </div>
    </h2>
  )
}

import { ModalButton } from 'src/design-system'
import * as React from 'react'
import { CREATE_FORM_MODAL_ID, OpenFormModalProps } from './utils'
import { trackEvent } from '../../services/event-tracker'
import { useStore } from 'store/context'
import { User } from 'store/modules/users'

export type CreateFormModalButtonProps = {
  className?: string
  colourVariant?: 'theme' | 'brand' | 'danger' | 'paper'
  label?: string | React.ReactNode
  variant?: 'default' | 'outline'
  title?: string
  user?: User
} & Omit<OpenFormModalProps, 'user'>

export const CreateFormModalButton: React.VFC<CreateFormModalButtonProps> = (
  props
) => {
  const { currentUser } = useStore()

  const {
    className,
    colourVariant,
    initialSkillIds,
    initialTabId,
    initialUserIds,
    label,
    createdFromId,
    createdFromType,
    resourceId,
    resourceType,
    source,
    tabs,
    title,
    user = currentUser,
    variant,
  } = props

  const hasActiveSubscription = !!currentUser?.org?.activeSubscription
  const modalId = hasActiveSubscription ? CREATE_FORM_MODAL_ID : ''

  const onClick = () => {
    trackEvent('$track_create_form_modal_opened', { source })

    if (hasActiveSubscription) return

    window.location.href = currentUser?.isAdmin
      ? `/orgs/${currentUser?.org?.slug}/billing/new`
      : '/'
  }

  return (
    <ModalButton
      className={className}
      colourVariant={colourVariant}
      modalId={modalId}
      modalProps={{
        source,
        tabs,
        initialSkillIds,
        initialTabId,
        initialUserIds,
        createdFromId,
        createdFromType,
        resourceId,
        resourceType,
        user,
      }}
      onClick={(e) => {
        e.stopPropagation()
        onClick()
      }}
      title={title}
      variant={variant}
    >
      {label || 'Add'}
    </ModalButton>
  )
}

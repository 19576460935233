import { Info } from '@phosphor-icons/react'
import { Link } from 'src/design-system'
import pluralize from 'pluralize'
import React from 'react'
import { User } from 'store/modules/users'

type UnfinishedCheckinsLinkProps = {
  count: number
  user: User
}

export const UnfinishedCheckinsLink = (props: UnfinishedCheckinsLinkProps) => {
  const { count, user } = props

  return (
    <div className="flex items-center">
      <Info className="h-4 mr-1.5 text-gray-900 w-4" />
      <span className="mr-1 text-gray-600">{count}</span>
      <Link
        className="p-0 text-gray-600"
        data-turbo="false"
        href={`/check-ins`}
        data-turbo-frame="content"
        data-turbo-action="advance"
      >
        active {pluralize('Check-in', count)}
      </Link>
    </div>
  )
}

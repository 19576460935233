import { GroupedOption, TSkillOption } from './skill-select.types'
import { Skill } from 'store/modules/skills'

export class SkillSelectVm {
  constructor(
    private skills: Skill[],
    private defaultSkillIds?: string[],
    private focusSkillIds?: string[]
  ) {}

  get defaultValue() {
    return this.skills
      .filter((skill) => {
        return this.defaultSkillIds?.includes(skill.id)
      })
      .map((skill) => this.formatSkillToOption(skill))
  }

  get options() {
    return this.focusSkillIds?.length && this.focusSkillIds.length > 0
      ? this.groupedOptions
      : this.singleOptions
  }

  private get singleOptions(): readonly TSkillOption[] {
    return this.skills.map((skill) => this.formatSkillToOption(skill))
  }

  private get groupedOptions(): readonly GroupedOption[] {
    const { focusSkills, otherSkills } = this.skills.reduce<{
      focusSkills: Skill[]
      otherSkills: Skill[]
    }>(
      (acc, skill) => {
        this.focusSkillIds?.includes(skill.id)
          ? acc.focusSkills.push(skill)
          : acc.otherSkills.push(skill)

        return acc
      },
      { focusSkills: [], otherSkills: [] }
    )

    return [
      {
        label: 'Focus Skills',
        options: focusSkills?.map((skill) =>
          this.formatSkillToOption(skill, true)
        ),
      },
      {
        label: 'Other Skills',
        options: otherSkills.map((skill) => this.formatSkillToOption(skill)),
      },
    ]
  }

  private formatSkillToOption(skill: Skill, isFocusSkill = false) {
    return {
      label: skill.name,
      value: skill.id,
      isFocusSkill,
    }
  }
}

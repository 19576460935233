import { observer } from 'mobx-react-lite'
import { LightbulbFilament } from '@phosphor-icons/react'
import * as React from 'react'
import { FeedbackFormFooterProps } from './feedback-form.types'

export const FeedbackFormFooter = observer((props: FeedbackFormFooterProps) => {
  const { vm } = props
  if (vm.from) return null
  return (
    <div className="rounded-b-lg bg-yellow-100 text-yellow-700 p-3 flex flex-row justify-center items-baseline gap-x-3">
      <div>
        <LightbulbFilament className="w-4 h-4 flex-shrink-0" weight="bold" />
      </div>
      <p className="m-0 text-orange-700">
        Have some difficult feedback to share? It may be better to deliver it
        face-to-face.
      </p>
    </div>
  )
})

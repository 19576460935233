import { Button, Input, Notice } from 'src/design-system'
import { observer } from 'mobx-react-lite'
import { ZodError } from 'zod'
import * as React from 'react'
import { CheckinFormFooter } from './checkin-form-footer'
import { CheckinFormVm } from './checkin-form-vm'
import { Editor, EditorContext } from 'components/atoms/editor'
import { PositionSelect } from 'components/position-select'
import { SkillSelect } from 'components/skill-select'
import { UnfinishedCheckinsLink } from './unfinished-checkins-link'
import { UserSelect } from 'components/user-select'
import { useStore } from 'store/context'

export type CheckinFormProps = {
  source: string
}

export const CheckinForm = observer((props: CheckinFormProps) => {
  const { source } = props

  const { users, currentUser, positions, featureFlags } = useStore()

  const [selectedPositionId, setSelectedPositionId] = React.useState<
    string | undefined | null
  >(currentUser?.position?.id)

  const editor = React.useRef<EditorContext>(null)

  const vm = React.useMemo(() => {
    return new CheckinFormVm(selectedPositionId, source)
  }, [selectedPositionId, source])

  const [formError, setFormError] = React.useState<string | null>(null)
  const [isSubmitting, setIsSubmitting] = React.useState(false)

  const onSubmit = React.useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      if (vm.disabled) return
      setIsSubmitting(true)

      try {
        const checkin = await vm?.onSubmit(e)
        editor.current?.reset()
        setFormError(null)
        if (checkin) location.assign(checkin.path)
      } catch (errors) {
        if (errors instanceof ZodError) {
          setFormError(
            'Please select assessor and position you want to Check-in for.'
          )
        } else {
          setFormError('Something went wrong. Please try again.')
        }

        // TODO: better error handling
        console.error({ errors })
      } finally {
        setIsSubmitting(false)
      }
    },
    [vm]
  )

  const onPositionChange = React.useCallback((positionId: string | null) => {
    setSelectedPositionId(positionId)
  }, [])

  const checkinSkillSelect = featureFlags.featureEnabled('checkin_skill_select')

  if (!vm) return null

  return (
    <>
      <form className="pb-6" onSubmit={onSubmit} id="checkin-form">
        <div className="flex items-baseline mb-1 mx-4 relative">
          <label
            htmlFor="title"
            className="m-0 h-full absolute left-3.5 text-sm flex flex-row items-center focus-within:border-red-500"
          >
            Title
          </label>
          <Input
            name="title"
            id="title"
            placeholder="E.g. Q2 check-in (optional)"
            naked
            className="text-sm w-full py-2 pl-[3.375rem] focus-within:border-theme-60 focus-within:!shadow-none"
          />
        </div>

        <div className="px-6">
          <UserSelect
            key="user-select-checkin"
            prefix="Assessor"
            users={users.withPackage('grow')}
            isMulti={false}
            name="assessor"
            required
            defaultValue={vm.defaultAssessorOption}
            className="w-full mx-1.5 mb-1 pr-4"
          />
          <PositionSelect
            className="w-full mx-1.5 mb-4 pr-4"
            value={vm.defaultPositionId}
            isDisabled={!vm.canCheckinAgainstAnyPosition}
            onChange={onPositionChange}
            placeholder={vm.disabled ? 'No positions' : 'Select position'}
            positions={positions.loading ? [] : vm.positions}
          />
          {!vm.disabled && (
            <Editor
              autoFocus
              ref={editor}
              name="description"
              placeholder="Any particular focus areas or goals for this check-in? (optional)"
              className="mb-4 -mx-1"
              rows={2}
            />
          )}
          {vm.disabled && (
            <Notice variant="info">
              You need a position with some skills before you can start a
              Check-in. Speak to your manager or admin to create one.
            </Notice>
          )}
          {!vm.disabled && (
            <div className="flex flex-col gap-y-2 sm:flex-row items-start sm:justify-between">
              {checkinSkillSelect && (
                <div className="flex flex-row items-center gap-x-2 flex-wrap gap-y-1">
                  <SkillSelect
                    name="skill_ids"
                    skills={vm.skills}
                    triggerContent={'All skills'}
                    focusSkillIds={vm.focusSkillIds}
                  />
                </div>
              )}
              <div className="flex gap-4 ml-auto">
                {vm.unfinishedCheckinsCount > 0 && currentUser && (
                  <UnfinishedCheckinsLink
                    count={vm.unfinishedCheckinsCount}
                    user={currentUser}
                  />
                )}
                <Button disabled={isSubmitting} className="my-px" type="submit">
                  Start Check-in
                </Button>
              </div>
            </div>
          )}
          {formError && <div className="mt-1 text-red-600">{formError}</div>}
        </div>
      </form>
      {vm.finalisedCheckinsCount === 0 && <CheckinFormFooter />}
    </>
  )
})

CheckinForm.displayName = 'CheckinForm'

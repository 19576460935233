import { format } from 'date-fns'

export class DateSelectVm {
  constructor(
    private selectedDate: Date | undefined,
    private placeholder: string
  ) {}

  formattedDate = this.selectedDate && format(this.selectedDate, 'dd MMM yyyy')
  hasValue = this.formattedDate !== undefined
  triggerContent = this.hasValue ? this.formattedDate : this.placeholder
}

import { Dropdown, Link } from 'src/design-system'
import { WithAnalytics } from 'components/with-analytics'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import Select from 'react-select'
import { store } from 'store/index'
import * as Components from './components'
import { TriggerContent } from './components/trigger'
import { SkillSelectVm } from './skill-select-vm'
import { SkillSelectProps, TSkillOption } from './skill-select.types'

export const SkillSelect = observer((props: SkillSelectProps) => {
  const {
    defaultSkillIds,
    focusSkillIds,
    menuIsOpen,
    name = 'skills',
    skills,
    noOptionsMessage: baseNoOptionsMessage,
    triggerContent,
    onChange,
    triggerProps = {},
    source,
  } = props

  const vm = React.useMemo(() => {
    return new SkillSelectVm(skills, defaultSkillIds, focusSkillIds)
  }, [skills, defaultSkillIds, focusSkillIds])

  const [value, setValue] = React.useState<readonly TSkillOption[] | null>(
    vm.defaultValue
  )

  const [inputValue, setInputValue] = React.useState('')
  const [open, setOpen] = React.useState(!!menuIsOpen)

  const noSkills = !skills || skills.length === 0

  const { currentUser } = store

  const noOptionsMessage = React.useMemo(() => {
    if (baseNoOptionsMessage) return baseNoOptionsMessage
    if (noSkills && !currentUser?.isAdmin) return 'No skills found'
    if (noSkills && currentUser?.isAdmin)
      return (
        <>
          No skills found.{' '}
          <WithAnalytics
            event="$track_skills_select_empty_state_add_skills"
            params={{ source }}
          >
            <Link
              className="p-0"
              href={currentUser?.org?.skillsPath}
              data-turbo-frame="content"
              data-turbo-action="advance"
            >
              Add some
            </Link>
          </WithAnalytics>
        </>
      )
    return 'Skill not recognised'
  }, [
    noSkills,
    baseNoOptionsMessage,
    currentUser?.isAdmin,
    currentUser?.org,
    source,
  ])

  return (
    <Dropdown.Root open={open} onOpenChange={setOpen}>
      <TriggerContent {...{ name, value, triggerContent, ...triggerProps }} />
      <Dropdown.Content align="start" forceMount={menuIsOpen || undefined}>
        <Select
          autoFocus
          defaultValue={vm.defaultValue}
          backspaceRemovesValue={false}
          components={Components}
          controlShouldRenderValue={false}
          hideSelectedOptions={false}
          isClearable
          blurInputOnSelect={false}
          onChange={(value) => {
            // TODO: figure out why controlled onChange causes focus to skip to the first option every time
            setValue(value)
            onChange?.(value.map((v) => v.value))
          }}
          value={value}
          menuIsOpen
          isMulti
          options={vm.options}
          noOptionsMessage={() => noOptionsMessage}
          placeholder="Add skills..."
          inputValue={inputValue}
          onInputChange={(value, action) => {
            // needed to persist input text when selecting an option
            if (action.action === 'input-change') setInputValue(value)
          }}
          onKeyDown={(event) => {
            if (event.key === 'Escape') setOpen(false)
          }}
          styles={{
            menu: () => ({}),
            groupHeading: () => ({}),
          }}
        />
      </Dropdown.Content>
    </Dropdown.Root>
  )
})

import { Dropdown } from 'src/design-system'
import cn from 'classnames'
import { VisibilityIcon } from 'components/visibility-icon'
import * as React from 'react'
import { VisibilityStatus } from '../../types/entities'
import { VisibilitySelectVm } from './visibility-select-vm'
import {
  VisibilityOption,
  VisibilitySelectProps,
} from './visibility-select.types'

export const VisibilitySelect = <E extends readonly VisibilityStatus[]>(
  props: VisibilitySelectProps<E>
) => {
  const {
    disabled,
    includedStatuses = ['org', 'reporting_line', 'only_me'] as const,
    initialStatus = includedStatuses[0],
    name = 'visibility_status',
    isForCurrentUser,
    isMultiUser = true,
  } = props
  const [open, setOpen] = React.useState(false)

  const vm = React.useMemo(() => {
    return new VisibilitySelectVm(
      initialStatus,
      includedStatuses,
      isForCurrentUser,
      isMultiUser
    )
  }, [initialStatus, includedStatuses, isForCurrentUser, isMultiUser])

  const [selectedOption, setSelectedOption] = React.useState<VisibilityOption>(
    vm.initialOption
  )

  React.useEffect(() => {
    if (
      selectedOption.value === 'only_me' &&
      !isForCurrentUser &&
      vm.initialOption
    ) {
      setSelectedOption(vm.initialOption)
    }
  }, [initialStatus, vm, selectedOption, isForCurrentUser])

  React.useEffect(() => {
    // reset the current option when changing isForCurrentUser, due to changing label text
    const opt = vm.getOption(selectedOption.value)
    if (opt) setSelectedOption(opt)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isForCurrentUser])

  return (
    <Dropdown.Root open={open} onOpenChange={setOpen}>
      <input type="hidden" name={name} value={selectedOption.value} />
      <Dropdown.Trigger
        variant="styled"
        disabled={disabled}
        className={cn(
          disabled &&
            'pointer-events-none text-gray-600 bg-white border-gray-100'
        )}
      >
        <VisibilityIcon visibility={selectedOption.value} includeTag={false} />
        {selectedOption.label}
      </Dropdown.Trigger>
      <Dropdown.Content>
        <Dropdown.Item
          disabled
          className="px-3 py-3.5 text-gray-600 text-xs font-bold mb-0"
        >
          Visible to...
        </Dropdown.Item>
        {vm.options.map((option) => (
          <Dropdown.Button
            type="button"
            key={option.value}
            className={
              'bg-transparent focus:!bg-gray-50 hover:!bg-gray-50 flex flex-row gap-x-2 px-4 py-3 items-center cursor-pointer text-gray-900'
            }
            onClick={() => {
              setSelectedOption(option)
              setOpen(false)
            }}
          >
            <VisibilityIcon visibility={option.value} useDarkPaperTag />

            {option.label}
          </Dropdown.Button>
        ))}
      </Dropdown.Content>
    </Dropdown.Root>
  )
}

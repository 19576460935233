import { AnimatePresence, motion } from 'framer-motion'
import {
  ChatDots,
  NumberCircleOne,
  NumberCircleThree,
  NumberCircleTwo,
  X,
} from '@phosphor-icons/react'
import { Link, useTimedContent } from 'src/design-system'
import * as React from 'react'
import { trackEvent } from 'app/packs/src/services/event-tracker'

export const CheckinFormFooter: React.VFC = () => {
  const { hideFor, show } = useTimedContent('checkinFormFooter')

  const onClick = () => {
    hideFor('forever')
    trackEvent('Check-in form footer dismissed')
  }

  return (
    <AnimatePresence>
      {show && (
        <motion.div
          exit={{ height: 0, opacity: 0 }}
          initial={{ height: 'auto', opacity: 1 }}
          transition={{ ease: 'easeInOut', duration: 0.2 }}
        >
          <div className="p-4 md:p-6 bg-gray-50">
            <button
              className="flex gap-1 items-center justify-center ml-auto text-gray-900 text-sm"
              onClick={onClick}
            >
              Hide
              <X aria-hidden weight="bold" />
            </button>
            <h6 className="font-bold text-gray-900 text-sm mb-2">
              How Check-ins work
            </h6>
            <ul className="list-none p-0 m-0 flex flex-col gap-y-2 relative">
              <li className="flex flex-row items-start gap-x-3 text-gray-600">
                <NumberCircleOne
                  weight="bold"
                  className="w-3.5 h-3.5 flex-shrink-0 flex mt-[0.2rem]"
                />
                Decide whether you’re ‘working towards’, ‘meeting’ or
                ‘exceeding’ a skill level, and add examples
              </li>
              <li className="flex flex-row items-start gap-x-3 text-gray-600">
                <NumberCircleTwo
                  weight="bold"
                  className="w-3.5 h-3.5 flex-shrink-0 flex mt-[0.2rem]"
                />
                Your assessor will do the same once you’ve finished
              </li>
              <li className="flex flex-row items-start gap-x-3 text-gray-600">
                <NumberCircleThree
                  weight="bold"
                  className="w-3.5 h-3.5 flex-shrink-0 flex mt-[0.2rem]"
                />
                Together you’ll agree a final level for each skill and next
                steps. This is private to you and your manager
              </li>
            </ul>
          </div>

          <div className="rounded-b-lg bg-orange-100 text-orange-700 p-3 flex flex-row justify-center items-baseline gap-x-3">
            <div>
              <ChatDots className="w-4 h-4 flex-shrink-0" weight="bold" />
            </div>
            <p className="m-0 text-orange-700">
              This is a new way of starting a Check-in —{' '}
              <Link
                className="text-orange-700 p-0"
                href="https://airtable.com/shrMZXwkilntRE9BA"
                isExternal
              >
                tell us what you think
              </Link>{' '}
              🙌
            </p>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

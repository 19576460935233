import { Button, Input, InputGroup, Loader } from 'src/design-system'
import React, { useEffect } from 'react'
import { store } from 'store/index'
import { StorySuggestionOutput } from 'store/modules/async-tasks'
import cn from 'classnames'

type Suggestion = StorySuggestionOutput['extracted'][number]
const getSuggestions = async (onComplete: (output: Suggestion[]) => void) => {
  const { created: task } = await store.asyncTasks.create(
    {
      type: 'open_ai',
      step: 'story-suggestion',
      attributes: {
        suggestions_limit: 3,
        cached: true,
      },
    },
    { source: 'story-form' }
  )
  if (!task) return []
  const result = await task.pollOne()
  if (!result.success) return []

  const suggestions = task.output.storySuggestion?.output
  if (suggestions) onComplete(suggestions.extracted)
}

export const StoryForm: React.VFC = () => {
  const [title, setTitle] = React.useState('')
  const [suggestions, setSuggestions] = React.useState<Suggestion[]>([])
  const [loading, setLoading] = React.useState(false)
  const mounted = React.useRef(false)

  useEffect(() => {
    if (!mounted.current) getSuggestions(setSuggestions)
    mounted.current = true
  }, [])

  const navigateToStory = async (
    title: string,
    updates: Array<{ type: string; id: string }> = []
  ) => {
    setLoading(true)
    const { createdId } = await store.stories.create({ title })
    if (!createdId) return
    await Promise.all(
      updates.map(async (update) => {
        await store.storyItems.create({
          storyableId: update.id,
          storyableType: update.type,
          story: createdId,
        })
      })
    )
    window.location.href = `/stories/${createdId}`
  }

  return (
    <>
      <form
        className={cn(
          'pb-6 px-6 flex flex-col gap-8',
          loading && 'pointer-events-none'
        )}
        id="story-form"
        onSubmit={(e) => {
          e.preventDefault()
          navigateToStory(title)
        }}
      >
        <div className="flex gap-4 items-end">
          <InputGroup.Root className="flex-grow">
            <InputGroup.Label>Story Title</InputGroup.Label>
            <Input
              placeholder="E.g. Improving the velocity of our team"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </InputGroup.Root>
        </div>
        {!suggestions.length ? (
          <div className="flex gap-2 items-center text-gray-500">
            <Loader variant="paper" className="h-6 w-6" /> Loading personalised
            suggestions based on your recent updates...
          </div>
        ) : (
          <div className="flex flex-col justify-start gap-2">
            <p className="font-bold">
              Suggestions based on your recent updates:
            </p>
            {suggestions.map((suggestion) => (
              <button
                type="button"
                className="text-left border-none appearance-none underline text-green-500"
                key={suggestion.title}
                onClick={() =>
                  navigateToStory(suggestion.title, suggestion.updates)
                }
              >
                {suggestion.title} ({suggestion.updates.length} updates)
              </button>
            ))}
          </div>
        )}
        <div className="flex gap-4 justify-end">
          <Button disabled={!title.length} type="submit">
            {loading ? 'Creating Story...' : 'Create a story'}
          </Button>
        </div>
      </form>
    </>
  )
}

import { X } from '@phosphor-icons/react'
import * as React from 'react'
import { ClearIndicatorProps, components } from 'react-select'
import { TSkillOption } from '../skill-select.types'

export const ClearIndicator = (
  props: ClearIndicatorProps<TSkillOption, true>
) => (
  <components.ClearIndicator {...props}>
    <X weight="bold" className="w-3 h-3 text-gray-600" aria-hidden />
    <span className="sr-only">Clear</span>
  </components.ClearIndicator>
)

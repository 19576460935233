export * from './clear-indicator'
export * from './control'
export * from './group'
export * from './group-heading'
export * from './indicators-container'
export * from './input'
export * from './menu-list'
export * from './no-options-message'
export * from './option'
export * from './placeholder'
export * from './value-container'

// components to remove
export const DropdownIndicator = null
export const IndicatorSeparator = null

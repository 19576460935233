import { Button, useMounted } from 'src/design-system'
import { observer } from 'mobx-react-lite'
import { ZodError } from 'zod'
import * as React from 'react'
import { Editor, EditorContext } from 'components/atoms/editor'
import { SkillSelect } from 'components/skill-select'
import { UserSelect } from 'components/user-select'
import { useStore } from 'store/context'
import { VisibilitySelect } from 'components/visibility-select'
import { WinFormProps } from './win-form.types'
import { WinFormVm } from './win-form-vm'

export const WinForm = observer((props: WinFormProps) => {
  const {
    autoFocus,
    category = 'Win',
    initialContent,
    initialSkillIds,
    initialUserIds,
    onSuccessfulSubmit,
    setContent,
    source,
    winId,
  } = props

  const { currentUser, skills, users, userSkills, wins, debug } = useStore()

  const win = winId ? wins.byId(winId) : undefined

  let defaultUserIds = React.useMemo(
    () => initialUserIds || [],
    [initialUserIds]
  )
  if (category === 'Note' && currentUser) defaultUserIds = [currentUser.id]
  if (win) defaultUserIds = win.winnerIds

  const [userIds, setUserIds] = React.useState<string[]>(defaultUserIds)
  const [submitCount, setSubmitCount] = React.useState(0)
  const editor = React.useRef<EditorContext>(null)
  const mounted = useMounted()

  const vm = React.useMemo(() => {
    if (!currentUser) return

    return new WinFormVm(
      currentUser,
      userIds,
      category,
      source,
      initialContent,
      initialSkillIds,
      win
    )
  }, [
    userIds,
    currentUser,
    category,
    source,
    initialContent,
    initialSkillIds,
    win,
  ])

  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const [formError, setFormError] = React.useState<string | null>(null)

  const onSubmit = React.useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      if (!vm) return

      setIsSubmitting(true)

      try {
        await vm.onSubmit(e)
        setSubmitCount(submitCount + 1)
        if (!win) editor.current?.reset()
        onSuccessfulSubmit?.()
        if (category === 'Win') setUserIds([])
      } catch (errors) {
        if (errors instanceof ZodError) {
          setFormError('Please enter content.')
        } else {
          setFormError('Something went wrong. Please try again.')
        }

        if (debug) console.error({ errors })
      } finally {
        if (mounted.current) setIsSubmitting(false)
      }
    },
    [category, onSuccessfulSubmit, submitCount, vm, debug, mounted]
  )

  React.useEffect(() => {
    async function fetchUserSkills() {
      await userSkills.fetchForUsers(userIds)
    }

    fetchUserSkills()
  }, [userIds, userSkills])

  const forCurrentUser = userIds.length === 1 && userIds[0] === currentUser?.id

  const focusSkillIds = forCurrentUser
    ? userSkills.focusedSkillIdsForUser(currentUser.id)
    : []

  const skillsForSelect = forCurrentUser
    ? currentUser.sortedSkills()
    : skills.requiredForUsersViaUserSkills(...userIds)

  if (win && userSkills.loading) return null

  if (!vm) return null

  return (
    <form className="pb-6 px-6" onSubmit={onSubmit} id="win-form">
      {category === 'Win' ? (
        <UserSelect
          className="mb-2.5 -mt-2"
          defaultValue={defaultUserIds}
          isDisabled={!!win}
          isMulti
          key={`user-select-${submitCount}`}
          onChange={setUserIds}
          prefix="For"
          required
          users={users.withPackage('grow')}
        />
      ) : (
        currentUser && (
          <input type="hidden" name="users" value={currentUser.id} />
        )
      )}
      <Editor
        autoFocus={autoFocus}
        ref={editor}
        initialContent={vm.defaultContentValue}
        onChange={setContent}
        name="content"
        rows={4}
        placeholder={vm.placeholderText}
        className="-mx-2 mb-4"
      />
      <div className="flex flex-col gap-y-2 sm:flex-row items-start sm:justify-between">
        <div className="flex flex-row items-center gap-x-2 flex-wrap gap-y-1">
          <SkillSelect
            key={`skill-select-${submitCount}`}
            skills={skillsForSelect}
            defaultSkillIds={vm.defaultSkillIds}
            focusSkillIds={focusSkillIds}
            noOptionsMessage={
              skillsForSelect.length === 0
                ? userIds.length > 0
                  ? 'No skills found'
                  : 'Select a user first'
                : undefined
            }
            source={source}
          />
          <VisibilitySelect
            key={`visibility-select-${submitCount}`}
            isForCurrentUser={vm.isForCurrentUser}
            initialStatus={vm.defaultVisibilityStatus}
            includedStatuses={vm.visibilityStatuses}
          />
        </div>
        <div className="flex justify-end w-full sm:w-auto">
          <Button disabled={isSubmitting} type="submit">
            {vm.buttonText}
          </Button>
        </div>
      </div>
      {formError && <div className="mt-1 text-red-600">{formError}</div>}
    </form>
  )
})

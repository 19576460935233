import { store } from 'store/index'
import { VisibilityStatus } from '../../types/entities'
import { VisibilityOption } from './visibility-select.types'

export class VisibilitySelectVm {
  constructor(
    private initialStatus: VisibilityStatus,
    private includedStatuses?: readonly VisibilityStatus[],
    private isForCurrentUser?: boolean,
    private isMultiUser?: boolean
  ) {
    if (includedStatuses?.length && !includedStatuses.includes(initialStatus)) {
      throw new Error(
        `Initial status "${initialStatus}" is not included in the list of included statuses`
      )
    }
  }

  readonly options = Object.values(this.filteredOptions)

  initialOption = this.baseOptions[this.initialStatus]

  getOption(value: VisibilityStatus) {
    return this.baseOptions[value]
  }

  private get filteredOptions() {
    if (this.includedStatuses) {
      return Object.values(this.baseOptions).filter((status) =>
        this.includedStatuses?.includes(status.value)
      )
    }

    return Object.values(this.baseOptions)
  }

  private get baseOptions() {
    const { currentUser } = store

    const reportingLineRecipientText = this.isMultiUser
      ? 'All recipients'
      : 'The recipient'

    const options: Record<VisibilityStatus, VisibilityOption> = {
      only_me: {
        value: 'only_me',
        label: 'Only you',
      },
      reporting_line: {
        value: 'reporting_line',
        label: this.isForCurrentUser
          ? 'You and your reporting line'
          : `${reportingLineRecipientText} and their reporting line`,
      },
      org: {
        value: 'org',
        label: currentUser?.org
          ? `Everyone at ${currentUser?.org?.name}`
          : 'Everyone in your org',
      },
    } as const

    return options
  }
}

import * as React from 'react'
import { GroupProps, components } from 'react-select'
import { TSkillOption } from '../skill-select.types'

export const Group = (props: GroupProps<TSkillOption, true>) => (
  <components.Group
    {...props}
    className="border-0 border-solid border-b pb-0 pt-2 border-gray-100 last-of-type:border-0"
  />
)

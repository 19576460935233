import * as React from 'react'
import { InputProps, components } from 'react-select'
import { TSkillOption } from '../skill-select.types'

export const Input = (props: InputProps<TSkillOption, true>) => (
  <components.Input
    {...props}
    className="m-0 p-0"
    inputClassName="shadow-none"
  />
)

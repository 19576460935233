import { sourceFromParams } from 'app/packs/src/services/tracker-helper-service'
import { store } from 'store/index'
import { FeedbackItemCreateAttributes } from 'store/modules/feedback-item'
import { z } from 'zod'
import { zfd } from 'zod-form-data'

export class FeedbackFormVm {
  constructor(public from: boolean, private feedbackRequestId?: string) {}

  async onSubmit(e: React.FormEvent<HTMLFormElement>, onSuccess?: () => void) {
    e.preventDefault()
    const formData = new FormData(e.currentTarget)

    const schema = zfd.formData({
      content: zfd.text(),
      users: z.preprocess(
        (val) => (typeof val === 'string' ? [val] : val),
        z.array(zfd.text())
      ),
    })
    const { content, users } = schema.parse(formData)

    if (this.from) {
      await store.feedbackRequests.create({
        content,
        users,
      })
    } else {
      const createData: FeedbackItemCreateAttributes = {
        content,
        receiver: users[0],
        skills: [],
        source: sourceFromParams(),
      }
      if (this.feedbackRequestId) {
        createData.feedbackRequest = this.feedbackRequestId
      }

      await store.feedbackItems.create(createData)
    }

    onSuccess?.()
  }

  get successMessage() {
    return this.from ? 'Feedback request submitted!' : 'Feedback submitted!'
  }

  get placeholder() {
    return this.from
      ? "Outline the feedback you'd like to receive. e.g. 'I'd love feedback on my communication skills from that meeting'"
      : 'Outline what they did, the impact it had, and what they could do differently next time. Try listing out what you’d like them to continue, start and stop.'
  }
}

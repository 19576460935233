import { Button, Notice } from 'src/design-system'
import { Editor, EditorContext } from 'components/atoms/editor'
import { DateSelect } from 'components/date-select'
import { SkillSelect } from 'components/skill-select'
import { VisibilitySelect } from 'components/visibility-select'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { store } from 'store/index'
import { ZodError } from 'zod'
import { ActionFormVm } from './action-form-vm'
import { ActionFormProps } from './action-form.types'

export const ActionForm = observer((props: ActionFormProps) => {
  const {
    actionId,
    actionSkills,
    autoFocus,
    initialContent,
    setContent,
    initialSkillIds,
    focusSkillIds,
    onSuccessfulSubmit,
    createdFromId,
    createdFromType,
    skills,
    source,
    user,
  } = props

  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const [formError, setFormError] = React.useState<string | null>(null)
  const action = actionId ? store.actions.byId(actionId) : undefined

  const vm = React.useMemo(() => {
    return new ActionFormVm(
      user,
      source,
      action,
      actionSkills,
      initialSkillIds,
      initialContent,
      createdFromId,
      createdFromType
    )
  }, [
    action,
    actionSkills,
    createdFromId,
    createdFromType,
    initialContent,
    initialSkillIds,
    source,
    user,
  ])

  const [submitCount, setSubmitCount] = React.useState(0)
  const editor = React.useRef<EditorContext>(null)

  const disabled = React.useMemo(() => !skills?.length, [skills])

  const onSubmit = React.useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      if (disabled) return
      setIsSubmitting(true)

      try {
        const id = await vm.onSubmit(e)
        onSuccessfulSubmit?.(id)
        setSubmitCount(submitCount + 1)
        editor.current?.reset()
        setFormError(null)
      } catch (errors) {
        if (errors instanceof ZodError) {
          setFormError('Please enter content and choose at least one skill.')
        } else {
          setFormError('Something went wrong. Please try again.')
        }

        // TODO: better error handling
        console.error({ errors })
      } finally {
        setIsSubmitting(false)
      }
    },
    [onSuccessfulSubmit, submitCount, vm, disabled]
  )

  return (
    <form className="pb-6 px-6" onSubmit={onSubmit}>
      <Editor
        ref={editor}
        name="content"
        autoFocus={autoFocus}
        onChange={setContent}
        placeholder={vm.placeholder}
        initialContent={vm.defaultContentValue}
        className="mb-4"
        rows={4}
      />
      <div className="mt-0.5 mb-2 flex flex-col gap-y-2 sm:flex-row items-start sm:justify-between">
        <div className="flex flex-row items-center gap-x-2 flex-wrap gap-y-1">
          <SkillSelect
            key={`skill-select-${submitCount}`}
            defaultSkillIds={vm.defaultSkillIds}
            focusSkillIds={focusSkillIds}
            name="skill_ids"
            required
            skills={skills || []}
            source={source}
          />
          <VisibilitySelect
            disabled
            includedStatuses={['reporting_line']}
            isForCurrentUser={user.isCurrentUser}
          />
          <DateSelect
            key={`date-select-${submitCount}`}
            name="due_date"
            defaultValue={action?.dueDate || undefined}
          />
        </div>

        <div className="flex justify-end w-full sm:w-auto">
          {!disabled && (
            <Button disabled={isSubmitting} type="submit">
              {vm.actionText}
            </Button>
          )}
        </div>
      </div>
      {disabled && store.currentUser?.org && (
        <Notice variant="info">
          Your position{' '}
          {store.currentUser.position &&
            `(${store.currentUser.position.name}) `}
          needs some skills before you can create an Action. Speak to your
          manager or admin to create one.
        </Notice>
      )}
      {formError && <div className="text-red-600">{formError}</div>}
    </form>
  )
})

import { store } from 'store/index'
import { Position } from 'store/modules/positions'
import { Skill } from 'store/modules/skills'
import { z } from 'zod'
import { zfd } from 'zod-form-data'

export class CheckinFormVm {
  constructor(
    private selectedPositionId: string | undefined | null,
    private source: string
  ) {}

  async onSubmit(e: React.FormEvent<HTMLFormElement>) {
    if (!store.currentUser) return
    e.preventDefault()

    const formData = new FormData(e.currentTarget)

    const schema = zfd.formData({
      title: zfd.text(z.string().optional()),
      description: zfd.text(z.string().optional()),
      position: zfd.text(
        z
          .string()
          .min(1)
          .default(this.selectedPositionId || '')
      ),
      assessor: zfd.text(z.string().min(1)),
      skill_ids: z.preprocess(
        (val) => (typeof val === 'string' ? [val] : val),
        z.array(zfd.text()).optional()
      ),
    })

    const { title, description, position, assessor, skill_ids } =
      schema.parse(formData)

    const createProps = {
      title: title,
      description,
      position,
      assessor,
      author: store.currentUser.id,
      initiated_by: 'self' as const,
      skill_ids,
      source: this.source,
    }

    const result = await store.checkins.create(createProps)

    const id =
      result.success && result.data
        ? Object.keys(result.data.checkins)[0]
        : undefined

    return store.checkins.byId(id)
  }

  get canCheckinAgainstAnyPosition() {
    return !!store.currentUser?.org?.canCheckinAgainstAnyPosition
  }

  get defaultAssessorOption(): string {
    return store.currentUser?.manager?.id || ''
  }

  get finalisedCheckinsCount(): number {
    return store.currentUser
      ? store.checkins.finalisedForAuthor(store.currentUser.id).length
      : 0
  }

  get unfinishedCheckinsCount(): number {
    if (!store.currentUser) return 0
    return store.checkins.notFinalisedForAuthor(store.currentUser.id).length
  }

  get defaultPositionId(): string {
    return store.currentUser?.position?.id || ''
  }

  get skills(): Skill[] {
    if (!this.selectedPosition) return []
    return this.selectedPosition.skills.filter((s): s is Skill => !!s)
  }

  get selectedPosition() {
    if (this.positions.length === 0) return
    if (!this.selectedPositionId) return

    return this.positions.find(
      (position) => position.id === this.selectedPositionId
    )
  }

  get focusSkillIds(): string[] {
    if (!store.currentUser) return []
    return store.userSkills.focusedSkillIdsForUser(store.currentUser.id)
  }

  get positions(): Position[] {
    if (this.canCheckinAgainstAnyPosition) return store.positions.forOrg
    if (store.currentUser?.position) return [store.currentUser.position]

    return []
  }

  get disabled() {
    return !this.positions.length
  }
}

import { Dropdown } from 'src/design-system'
import cn from 'classnames'
import { Star } from '@phosphor-icons/react'
import * as React from 'react'
import { TriggerContentProps } from '../skill-select.types'

export const TriggerContent = (props: TriggerContentProps) => {
  const {
    value,
    name = 'skills',
    triggerContent = 'Skills',
    showSelected = true,
    showStar = true,
    ...restProps
  } = props

  let content = triggerContent

  if (showSelected && value) {
    if (value.length === 1) content = value[0].label
    if (value.length === 2) content = value.map((v) => v.label).join(', ')
    if (value.length > 2) content = `${value[0].label}, +${value.length - 1}`
  }

  const hasValue = value && value.length > 0

  return (
    <Dropdown.Trigger
      variant="styled"
      {...restProps}
      data-testid="skill-select-trigger"
    >
      {showStar && (
        <Star
          weight="bold"
          className={cn(
            hasValue ? 'text-theme-50' : 'text-gray-600',
            'w-4 h-4'
          )}
          aria-hidden
        />
      )}
      <div
        className={cn(
          'flex gap-1 items-center',
          value && showSelected && value.length > 0 && 'text-gray-900'
        )}
      >
        {content}
      </div>
      {value?.map((option) => (
        <input
          type="hidden"
          name={name}
          value={option.value}
          key={option.value}
        />
      ))}
    </Dropdown.Trigger>
  )
}
